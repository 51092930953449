<template>
  <div ref="dropNavbar">
    <nav class="navbar navbar-expand-md bg-ghost-white">
      <div class="container-xxl justify-content-md-between">
        <NuxtLink
          class="navbar-brand mx-md-0 d-flex align-items-center align-items-center"
          :to="!client.isAuthenticated ? '/' : (client.getActiveCompany !== null ? `/company/${client.getActiveCompany.id}/vacancies` : '/applicant/responses')"
          @click="isOpenNav = false"
        >
          <img
            src="/public/logo.svg"
            height="40"
            width="120"
            alt="Sobes"
          >
        </NuxtLink>
        <div
          v-if="client.isAuthenticated"
          class="d-md-block d-flex gap-15"
        >
          <div class="position-relative d-md-none">
            <div
              v-show="isActiveProfileBadge"
              class="position-absolute top-0 start-100 rounded-circle translate-middle bg-primary"
              style="width: 10px; height: 10px;"
            />
            <AppButton
              :variant="BtnVariant.Tertiary"
              is-icon
              @click="isOpenNav = !isOpenNav"
            >
              <svg
                v-if="isOpenNav === false"
                class="h-100 w-100"
                style="width: 40px; height: 40px;"
              >
                <use xlink:href="/public/icons/auth-sprite.svg#account-circle" />
              </svg>
              <svg
                v-else
                class="h-100 w-100"
              >
                <use xlink:href="/public/icons/main.svg#close" />
              </svg>
            </AppButton>
          </div>
        </div>
        <AppButton
          v-if="!client.isAuthenticated"
          class="fw-bold"
          to="/login"
          :variant="BtnVariant.Tertiary"
          @click="isOpenNav = false"
        >
          Войти
        </AppButton>
        <div
          v-if="client.isAuthenticated"
          id="navbarContent"
          class="collapse navbar-collapse flex-grow-0 w-100 panel bg-ghost-white bg-md-transparent"
          :class="isOpenNav ? 'show' : null"
        >
          <div class="d-flex flex-column flex-md-row justify-content-end align-items-xl-center gap-20 gap-xl-0 w-100">
            <div
              v-if="client.isAuthenticated"
              class="d-md-flex gap-15 gap-xl-25 align-items-center ms-md-auto"
            >
              <div
                v-click-outside="closeProfile"
                class="position-md-relative d-flex d-md-block flex-wrap justify-content-center justify-content-sm-between gap-25"
              >
                <div
                  class="d-flex align-items-center w-100 w-sm-auto"
                  style="cursor: pointer"
                  @click="isOpenProfile = !isOpenProfile"
                >
                  <div
                    class="position-absolute top-0 start-100 rounded-circle translate-middle d-none bg-primary"
                    :class="{'d-md-block': isActiveProfileBadge}"
                    style="width: 10px; height: 10px;"
                  />
                  <div
                    class="overflow-hidden"
                    style="max-width: 160px; text-overflow: ellipsis;"
                  >
                    <span
                      class="text-dark fw-semiBold"
                      :title="getLogin"
                    >
                      {{ getLogin }}
                    </span>
                    <span
                      class="d-block"
                      :title="activeClientType()"
                      style="overflow: hidden; text-overflow: ellipsis; white-space: nowrap;"
                    >
                      {{ activeClientType() }}
                    </span>
                  </div>
                </div>
                <div
                  class="dropdown-menu p-10 position-unset dropdown-menu-end d-block d-md-none w-100 end-0 shadow rounded-20 overflow-hidden bg-md-ghost-white"
                  :class="{'show': isOpenProfile}"
                >
                  <ul class="rounded-md-20 bg-md-white shadow-md list-unstyled overflow-hidden">
                    <template v-if="client.role === 0 || client.isAdmin">
                      <li
                        v-if="client.isSummaryCreated"
                        class="px-20 py-5"
                      >
                        <NuxtLink
                          class="btn w-100 text-start d-flex justify-content-between align-items-center border border-primary rounded-20 px-24 py-5 heading-text-2 lh-base"
                          :class="{ 'opacity-50': client.getActiveCompany !== null }"
                          style="height: 56px;"
                          to="/applicant/responses"
                          @click="closeNav(); client.logoutCompany()"
                        >
                          <div>
                            <div class="text-primary fw-bold">
                              {{ client.name + ' ' + client.surname }}
                            </div>
                            моё резюме
                          </div>
                          <SvgCheck
                            width="24"
                            height="24"
                            :check="client.getActiveCompany === null"
                          />
                        </NuxtLink>
                      </li>
                      <li
                        v-else
                        class="px-20 py-5"
                      >
                        <AppButton
                          class="w-100 fw-bold"
                          style="height: 56px;"
                          :variant="BtnVariant.Tertiary"
                          to="/applicant/summaries"
                          @click="closeNav"
                        >
                          Создать видеорезюме
                          <template #append-icon>
                            <svg class="w-100 h-100">
                              <use xlink:href="/public/icons/main.svg#east" />
                            </svg>
                          </template>
                        </AppButton>
                      </li>
                    </template>
                    <template v-if="client.role === 1 || client.isAdmin">
                      <li class="px-20 py-5">
                        <AppButton
                          class="w-100 fw-bold"
                          style="height: 56px;"
                          :variant="BtnVariant.Tertiary"
                          :to="`/company/${client.getActiveCompany?.id}/vacancies/create`"
                          @click="closeNav"
                        >
                          Создать видеовакансию
                          <template #append-icon>
                            <svg class="w-100 h-100">
                              <use xlink:href="/public/icons/main.svg#east" />
                            </svg>
                          </template>
                        </AppButton>
                      </li>
                      <li
                        v-for="company in client.companies"
                        class="px-20 py-5"
                      >
                        <button
                          class="btn w-100 text-start d-flex justify-content-between align-items-center border border-primary rounded-20 px-24 py-5 heading-text-2 lh-base"
                          :class="{ 'opacity-50': company.id !== client.getActiveCompany?.id }"
                          style="height: 56px;"
                          @click="changeCompany(company)"
                        >
                          <div class="overflow-hidden">
                            <div
                              class="text-primary fw-bold overflow-hidden"
                              style="text-overflow: ellipsis; white-space: nowrap;"
                            >
                              {{ company.name }}
                            </div>
                            {{ company.vacanciesCount }} {{ useDeclination(company.vacanciesCount, 'вакансия', 'вакансии', 'вакансий') }}
                          </div>
                          <SvgCheck
                            width="24"
                            height="24"
                            :check="company.id === client.getActiveCompany?.id"
                          />
                        </button>
                      </li>
                      <li class="px-20 py-5">
                        <AppButton
                          class="w-100 text-gray"
                          :variant="BtnVariant.Link"
                          @click="closeNav(); modalShow = true"
                        >
                          Добавить компанию
                          <template #append-icon>
                            <svg class="text-primary w-100 h-100">
                              <use xlink:href="/public/icons/auth-sprite.svg#add" />
                            </svg>
                          </template>
                        </AppButton>
                      </li>
                    </template>
                    <li class="px-20">
                      <hr class="dropdown-divider">
                    </li>
                    <li>
                      <NuxtLink
                        class="dropdown-item"
                        to="/favorites"
                        @click="closeProfile(); isOpenNav = false"
                      >
                        Избранные {{ client.getActiveCompany === null ? 'вакансии' : 'кандидаты' }}
                        <span
                          v-show="favoritesCount > 0"
                          class="text-primary fw-bold"
                        >
                          {{ favoritesCount < 1 ? 0 : (favoritesCount > 9 ? '9+' : favoritesCount) }}
                        </span>
                      </NuxtLink>
                    </li>
                    <li>
                      <NuxtLink
                        class="dropdown-item"
                        to="/notifications"
                        @click="closeProfile(); isOpenNav = false"
                      >
                        Уведомления
                        <span
                          v-show="newNotificationsCount > 0"
                          class="text-primary fw-bold"
                        >
                          {{ newNotificationsCount < 1 ? 0 : (newNotificationsCount > 9 ? '9+' : newNotificationsCount) }} новых
                        </span>
                      </NuxtLink>
                    </li>
                    <li>
                      <NuxtLink
                        class="dropdown-item"
                        to="/chats"
                        @click="closeProfile(); isOpenNav = false"
                      >
                        Чаты
                        <span
                          v-show="getNewMessageCount > 0"
                          class="text-primary fw-bold"
                        >
                          {{ getNewMessageCount > 9 ? '9+' : getNewMessageCount }} новых
                        </span>
                      </NuxtLink>
                    </li>
                    <li>
                      <NuxtLink
                        class="dropdown-item"
                        to="/settings"
                        @click="closeProfile(); isOpenNav = false"
                      >
                        Профиль
                      </NuxtLink>
                    </li>
                    <li v-if="client.isAdmin">
                      <NuxtLink
                        class="dropdown-item"
                        to="/admin/dashboard"
                        @click="closeNav"
                      >
                        Админ. панель
                      </NuxtLink>
                    </li>
                    <li>
                      <span
                        class="dropdown-item"
                        style="cursor: pointer;"
                        @click="signOut"
                      >
                        Выход
                      </span>
                    </li>
                  </ul>
                  <ul class="d-md-block d-none w-100 mb-0 list-unstyled text-center">
                    <li class="mb-10">
                      <NuxtLink
                        class="text-dark text-decoration-none"
                        to="/about?theme=2"
                        @click="closeProfile(); isOpenNav = false"
                      >
                        Контакты
                      </NuxtLink>
                    </li>
                    <li>
                      <NuxtLink
                        class="text-dark text-decoration-none"
                        :to="'/help?theme=' + (client.role === 0 ? '1' : '2')"
                        @click="closeProfile(); isOpenNav = false"
                      >
                        Помощь
                      </NuxtLink>
                    </li>
                  </ul>
                </div>
                <ul class="d-md-none w-100 mb-0 list-unstyled">
                  <li class="mb-20">
                    <NuxtLink
                      class="text-dark text-decoration-none"
                      to="/about?theme=2"
                      @click="isOpenNav = false"
                    >
                      Контакты
                    </NuxtLink>
                  </li>
                  <li>
                    <NuxtLink
                      class="text-dark text-decoration-none"
                      :to="'/help?theme=' + (client.role === 0 ? '1' : '2')"
                      @click="isOpenNav = false"
                    >
                      Помощь
                    </NuxtLink>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </nav>
    <div
      v-if="route.path !== '/'"
      class="bg-primary bg-brand-gradient"
    >
      <div class="container py-10 search-custom-size">
        <div class="row">
          <SearchComponent class="col-xl-9 mx-auto" />
        </div>
      </div>
    </div>
  </div>
  <VueFinalModal
    v-model="modalShow"
    content-class="modal-dialog modal-dialog-centered modal-md"
    display-directive="show"
  >
    <div class="modal-content rounded-20">
      <div class="modal-header border-0">
        <p class="heading-section-3 modal-title fs-5 w-100 fw-bold">
          Добавить компанию
        </p>
        <button
          class="btn-close"
          type="button"
          aria-label="Закрыть"
          title="Закрыть"
          @click="modalShow = false"
        />
      </div>
      <div class="modal-body">
        <form
          class="d-flex flex-column gap-20"
          @submit.prevent="submit"
        >
          <div class="form-group">
            <input
              v-model.trim="name"
              type="text"
              class="form-control rounded-pill"
              :class="{'is-invalid': !isAddCompany && $v.name.$error}"
              placeholder="Название компании"
            >
          </div>
          <div class="form-group">
            <input
              v-model.trim="tin"
              type="text"
              class="form-control rounded-pill"
              :class="{'is-invalid': !isAddCompany && $v.tin.$error}"
              placeholder="ИНН"
            >
            <div class="invalid-feedback">
              ИНН должен содержать не менее 10 и не более 12 символов
            </div>
          </div>
          <div class="form-group">
            <CheckBox
              v-for="(item) in typesCompany"
              :val="item.val"
              :is-checked="item.val === checkedValue"
              is-radio
              @change="checkedValue = item.val"
            >
              {{ item.title }}
            </CheckBox>
          </div>
          <div>
            <AppButton
              type="submit"
              class="fw-bold w-100"
              :disabled="loading"
              :variant="BtnVariant.Primary"
            >
              Добавить
            </AppButton>
          </div>
        </form>
        <p class="text-gray mt-20 text-center">
          Добавляя компанию, вы принимаете условия
          <NuxtLink
            class="text-primary text-decoration-none"
            to="/documents?theme=2"
            @click="modalShow = false"
          >
            Правил и Соглашения об использовании сайта Sobes.ru
          </NuxtLink>
          и даете Согласие на обработку
          <NuxtLink
            class="text-primary text-decoration-none"
            to="/documents?theme=1"
            @click="modalShow = false"
          >
            персональных данных
          </NuxtLink>
        </p>
      </div>
    </div>
  </VueFinalModal>
</template>

<script setup lang="ts">
import { useClient } from "~/stores/client";
import { useApiCompanies } from "~/composables/api/companies";
import { onClickOutside } from '@vueuse/core'
import { VueFinalModal } from 'vue-final-modal';
import { useVuelidate } from '@vuelidate/core'
import { maxLength, minLength, numeric, required } from "@vuelidate/validators";
import CheckBox from "~/components/inputs/checkBox.vue";
import { BtnVariant } from "~/composables/enums/BtnVariant";
import { useDeclination } from "../composables/utils/Declination";
import type { NewMessageCount } from "~/entities/NewMessageCount";

const { create } = useApiCompanies();
const { $toast } = useNuxtApp();
const client = useClient();
const route = useRoute();
const router = useRouter();

//-----VARIABLES-----\\
const typesCompany = [
  {val: 1, title: 'Прямой работодатель'},
  {val: 2, title: 'Кадровое агенство'}
];

//-----STATE-----\\
const newNotificationsCount = useState<number>('newNotificationsCount');
const newMessagesCount = useState<NewMessageCount[]>('newMessagesCount');
const favoritesCount = useState<number>('favoritesCount');
const isOpenNav = ref<boolean>(false);
const isOpenProfile = ref<boolean>(false);
const dropNavbar = ref();
const modalShow = ref<boolean>(false);
const name = ref<string>('');
const tin = ref<string>('');
const checkedValue = ref<number>(1);
const isAddCompany = ref<boolean>(false);
const loading = ref<boolean>(false);

const $v = useVuelidate({
  name: { required },
  tin: { required, numeric, minLength: minLength(10), maxLength: maxLength(12) }
}, {
  name,
  tin
});

//-----COMPUTED-----\\
const getLogin= computed((): string => {
  if (client.name !== null) {
    return client.name + ' ' + client.surname;
  } else if (client.phone !== null) {
    return client.phone;
  } else if (client.email !== null) {
    return client.email;
  }
  return '';
});
const getNewMessageCount = computed<number>(() => {
  let count = 0;
  newMessagesCount.value.forEach(item => {
    count += item.count;
  });

  return count;
});
const isActiveProfileBadge = computed<boolean>(() => {
  return getNewMessageCount.value > 0 || newNotificationsCount.value > 0
});

watch(() => client.isAuthenticated, (val) => {
  if (val) {
    getCountFavorites();
  }
});

//-----METHODS-----\\
async function getCountFavorites() {
  await mainFetch(`client/${client.getClientId}/favorite`, {
    query: {
      isGetCount: true,
      type: client.getActiveCompany === null ? 1 : 0
    }
  }).then((resp) => {
    resp = resp.data.value;
    favoritesCount.value = resp.count;
  });
}
function signOut() {
  client.signOut();
  closeNav();
}
onClickOutside(dropNavbar, () => isOpenNav.value = false);
function closeProfile() {
  isOpenProfile.value = false;
}
function closeNav() {
  closeProfile();
  isOpenNav.value = false;
}
function activeClientType() {
  const company = client.getActiveCompany;
  return company === null ? 'Соискатель' : company.name;
}
function changeCompany(company: any) {
  closeNav();
  if (company.id === client.getActiveCompany?.id) {
    router.push(`/company/${company.id}/vacancies${company.isVacancyCreated ? '' : '/preview/responses'}`);
  } else {
    client.loginCompany(company);
  }
}
async function submit() {
  isAddCompany.value = false;
  $v.value.$touch();
  if ($v.value.$invalid) {
    return;
  }

  loading.value = true;

  await create({ name: name.value, tin: tin.value, typeCompany: checkedValue.value })
    .then(response => {
      response = response.data.value??response.error.value?.data;
      if (response.error.code === 201) {
        const company = {
          id: response.id,
          name: name.value,
          isVacancyCreated: false,
          vacanciesCount: 0,
        };
        client.companies.push(company);
        $toast.success('Компания добавлена');
        isAddCompany.value = true;
      } else {
        isAddCompany.value = false;
        $toast.error(response.error.clientMessage ?? response.error.message);
      }
    });

  name.value = '';
  tin.value = '';

  loading.value = false;
  modalShow.value = false;
  isOpenNav.value = true;
}
</script>

<style scoped lang="scss">
.dropdown-menu.show {
  display: block !important;
}
@media (max-width: 767px) {
  .position-unset {
    position: unset !important;
  }
}
.search-custom-size {
  @media (max-width: 991px) and (min-width: 768px) {
    max-width: 820px;
  }
}
.panel {
  @media (max-width: 767px) {
    background: white;
    position: absolute;
    top: 80px;
    left: 0;
    z-index: 999;
    padding: 20px;
  }
  @media (min-width: 768px) {
    .dropdown-menu {
      width: 280px !important;
      top: calc(100% + 5px);
    }
  }
}
</style>
